html.mm-opening .mm-menu.mm-opened[class*="mm-pagedim"] ~ #mm-blocker {
  opacity: 0.6;
}

.mm-menu {
  background: $off-white;

  .mm-btn {
    height: 51px;

    &:after,
    &:before {
      border-color: $white;
    }
  }
}

.mm-panels > .mm-panel.mm-hasnavbar {
  padding-top: 85px;
}

.mm-menu .mm-navbar a,
.mm-menu .mm-navbar > * {
  font-weight: $font-weight-bold;
  color: white;
}

.mm-navbar {
  background: $color-primary;
  height: 51px;
  .mm-prev {
    &:before {
      border-color: $color-primary !important;
    }
  }
}

.mm-listview {
  padding: 0 20px;

  > li > a,
  .mm-listview > li > span {
    border-radius: 10px;
    background: $gray;
    padding: 13px;
    padding-bottom: 13px;

    &:hover {
      background: $color-secondary;
    }

    &:focus,
    &:active {
      background: $color-primary;
      color: $white;
    }
  }

  > li:not(.mm-divider)::after {
    left: 0;
    border-bottom-width: 2px;
    border-bottom-color: $off-white;
  }
}

.hamburger {
  padding: 0 $spacer 0 40px;
  height: 32px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  margin: 0;
  overflow: visible;
  position: relative;
  outline: none !important;
  box-shadow: none !important;
  background: none !important;
  color: $white;
  text-transform: uppercase;
  font-size: $font-size-extra-small;
  border-radius: 0;
  border: none;
  outline: none;

  @include mq($screen-s) {
    height: 50px;
    padding: 0 $spacer--medium 0 66px;
    font-size: $font-size-small;
    margin-right: $spacer--medium;
  }

  .hamburger-box {
    width: 24px;
    height: 14px;
    display: inline-block;
    position: relative;
    position: absolute;
    top: 50%;
    left: $spacer;
    transform: translate(0, -50%);

    @include mq($screen-s) {
      width: 32px;
      height: 24px;
      left: $spacer--medium;
    }

    .hamburger-inner {
      display: block;
      top: 50%;
      margin-top: -2px;

      &:before,
      &:after {
        content: "";
        display: block;
      }

      &:before {
        top: -10px;
      }

      &:after {
        bottom: -10px;
      }
    }

    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
      width: 24px;
      height: 2px;
      background-color: $green;
      border-radius: 4px;
      position: absolute;
      transition-property: transform;
      transition-duration: 0.15s;
      transition-timing-function: ease;

      @include mq($screen-s) {
        width: 32px;
      }
    }
  }

  &.hamburger--elastic {
    position: relative;
    top: unset;
    right: unset;
    margin-right: 0;
    .hamburger-inner {
      top: 2px;
      transition-duration: 0.275s;
      transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);

      &:before {
        top: 6px;
        transition: opacity 0.125s 0.275s ease;
      }

      &:after {
        top: 12px;
        transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
      }

      @include mq($screen-s) {
        top: 2px;

        &:before {
          top: 10px;
        }

        &:after {
          top: 20px;
        }
      }
    }

    &.is-active {
      .hamburger-inner {
        transform: translate3d(0, 6px, 0) rotate(135deg);
        transition-delay: 0.075s;

        &:before {
          transition-delay: 0s;
          opacity: 0;
        }

        &:after {
          transform: translate3d(0, -12px, 0) rotate(-270deg);
          transition-delay: 0.075s;
        }

        @include mq($screen-s) {
          transform: translate3d(0, 10px, 0) rotate(135deg);

          &:after {
            transform: translate3d(0, -20px, 0) rotate(-270deg);
          }
        }
      }
    }
  }
}

.mm-listview > .icon-items {
  border-top: 1px solid $gray;
  margin: 28px 0 15px;
  padding-top: 28px;

  &:after {
    display: none !important;
  }

  > a {
    line-height: 1.2;
    padding-left: 78px;
    position: relative;
    background: none !important;
    overflow: visible;
    white-space: normal;
    word-break: break-all;
    strong {
      display: block;
    }

    &:before {
      width: 55px;
      height: 55px;
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(0, -50%);
      display: block;
      background-color: $color-primary;
      background-repeat: no-repeat;
      background-position: center center;
      border-radius: 1000px;
      background-size: 24px 24px;
      -webkit-transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      -o-transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }

    &:hover,
    &:focus,
    &:active {
      background: none !important;
      color: $color-primary;

      &:before {
        transform: translate(0, -50%) scale(1.2);
      }
    }
  }
  &.blog > a:before {
    background-image: url(../images/icons/blog.svg);
  }
  &.phone > a:before {
    background-image: url(../images/icons/phone.svg);
  }

  &.email > a:before {
    background-image: url(../images/icons/email.svg);
  }

  + .icon-items {
    border: 0;
    margin-top: 0;
    padding-top: 0;
  }
}
.mm-panel.mm-hasnavbar .mm-navbar {
  background: url("../images/menu_bg.svg") no-repeat;
  background-size: cover;
  min-height: 64px;
  border: none;
}
