.breadcrumbs {
  padding: $spacer--medium 0;
  margin-bottom: 50px;
  border-bottom: 1px solid $gray;
  max-width: unset;
  @include global-margin;

  &__link {
    background: $gray-lightest;
    padding: $breadcrumbs__padding !important;
    border-radius: 1000px;

    &:hover {
      background: $color-primary;
      color: $white;
      text-decoration: none;
    }
  }

  &__item {
    position: relative;
    margin-right: 10px;

    &:before,
    &:after {
      display: none;
    }
  }
}
