@import "imports";

html,
body {
  background: $white;

  b,
  strong {
    font-weight: $font-weight-bold;
  }
  .cms-page__content {
    ol {
      counter-reset: item;
      li {
        list-style: none;
        font-weight: 300;
        margin-bottom: 10px;
        &:before {
          content: counters(item, ".") " ";
          counter-increment: item;
          font-weight: 700;
        }
      }
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  strong {
    font-weight: $headings-font-weight;
    color: $green;
  }
}

#maincontent,
.footer,
.modals-wrapper,
.modal__container {
  .button {
    overflow: hidden;
  }
  .message {
    background-color: $color-primary;
  }
}

.footer {
  margin-top: 0;
  padding-top: 148px;
  position: relative;

  &:before {
    content: "";
    width: 100%;
    height: 0;
    padding-top: 7.7083%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    background: url(../images/pre_footer.svg) center / contain no-repeat #fff;
  }

  &__handler {
    display: flex;
    flex-wrap: nowrap;
    padding-left: 0;
    padding-right: 0;
  }

  &__logo {
    flex: 1 0 140px;

    svg {
      max-width: 80px;
    }
    img {
      mix-blend-mode: multiply;
    }
  }

  &__links {
    flex: 1 1 100%;
    column-gap: 20px;
    column-count: 2;
    padding: 0 15px;
    @include mq($screen-m) {
      column-gap: 40px;
    }

    @include mq($screen-l) {
      column-count: 4;
    }
  }

  &-menu {
    &__list {
      list-style: none;
      margin-top: 0;
      padding-left: 0;

      button {
        background: none;
        border: 0;
        text-align: left;
      }
    }

    &__item {
      a {
        color: $paragraph-color;
      }
    }
  }
  &__bottom-bar-handler {
    border-top: 1px solid $gray;
  }
  &__social-handler {
    order: 1;
    flex: 0 1 auto;
    padding: 0;
    border: 0;

    &:before {
      display: none;
    }

    .list__item {
      position: relative;
      a {
        svg {
          fill: $gray-dark;
        }
      }
    }
    .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
.page-wrapper {
  margin-bottom: 0;
  padding-bottom: 80px;
  @include mq($screen-m) {
    padding-bottom: 0;
  }
  .filters__modal.modal--active {
    z-index: 1000;
  }
}

#maincontent.homepage {
  .content-bg-wrapper {
    background: $off-white;
    margin-bottom: 40px;
    position: relative;
    z-index: 1;

    @include mq($screen-m) {
      margin-bottom: 90px;
    }
    &:after {
      content: "";
      background: url(../images/content_bg.svg) center / cover no-repeat #fff;
      width: 100%;
      height: 0;
      padding-top: 27.395%;
      position: absolute;
      left: 0;
      bottom: -15%;
      z-index: -1;
      pointer-events: none;
    }
  }
}
.homepage-block {
  @include global-padding;
  padding-top: 50px;
  padding-bottom: 50px;
  background: #fff;
  color: $white;
  @include mq($screen-m) {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul {
    color: $white;
  }

  @include mq($screen-l) {
    display: flex;
    align-items: center;
    align-items: stretch;
  }

  > div {
    width: 100%;
  }

  h1 {
    font-size: $font-size-large;
    text-transform: uppercase;
    font-weight: 300;
    color: $white;
    line-height: $font-size-large;
    margin-top: 0;

    @include mq($screen-m) {
      font-size: $font-size-extra-large;
      line-height: $font-size-extra-large;
    }

    @include mq($screen-xl) {
      font-size: 25px;
      line-height: 30px;
    }

    > strong {
      padding: 8px 20px;
      box-decoration-break: clone;
      display: inline;
      color: $white;
      font-weight: $font-weight-normal;

      strong {
        font-weight: $font-weight-extra-bold;
        color: $gray-darkest;
      }
    }
  }

  p {
    font-size: 14px;
  }

  &__left {
    min-height: 250px;
    background: $green;
    border-radius: 120px 130px 90px 100px;
    padding: 40px;
    position: relative;
    z-index: 10;
    &__inner {
      a {
        color: $yellow;
        &:hover {
          color: #fff;
        }
      }
    }

    @include mq($screen-l) {
      background: url(../images/homepage_block.svg) left center / contain
        no-repeat;
      position: relative;
      padding: 0px;
      border-radius: 0;
      &__inner {
        margin-bottom: $spacer--large;
        position: absolute;
        top: 50%;
        left: 50%;
        right: 0;
        width: 70%;
        -moz-transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        padding: 0 10% 0 0;
        margin-bottom: 0;
        p {
          font-size: 14px;
        }
        @include mq($screen-xl) {
          padding-left: 5%;
          width: 80%;
          p {
            font-size: 16px;
          }
        }
        @include mq($screen-xxl) {
          padding: 0 10% 0 5%;
        }
      }
    }
  }
  &__right {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1px;
    background: #fff;

    @include mq($screen-s) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    p {
      width: 100%;
      position: relative;
      z-index: 1;
      height: 0;
      padding-top: 100%;
      margin: 0;
      background: #fff;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
        transition: filter 0.3s ease;
        filter: grayscale(0);
      }

      a {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 20%;

        &:hover {
          img {
            filter: grayscale(1);
          }
        }

        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 2;
        }
      }
    }
  }
}

.checkout-cart-index,
.catalog-category-view,
.account,
.contact-index-index {
  #maincontent {
    max-width: unset;
    @include global-padding;
  }
}

.home {
  margin-bottom: 60px;

  h1 {
    margin-top: 0;
    text-align: center;
    line-height: 1.3;
  }
}

#contentarea {
  @include visually-hidden;
}
.header .header__buttons {
  margin-left: auto;
}
.header {
  nav.navigation {
    display: none;
  }
  .logo {
    max-width: 350px !important;
  }
}

.pdp-downloads-wrapper {
  .product-downloads {
    a {
      display: block;
      position: relative;
      padding-left: 25px;
      svg {
        position: absolute;
        left: 0;
        top: 0;
        width: 20px;
      }
      &:hover {
        color: $color-secondary;
      }
    }
  }
}

.catalog-list__banner {
  background: url(../images/cat_banner.jpg) right / contain no-repeat #fff;
  .banner__content--category {
    background: #fff;
  }
  &.banner--category-image {
    background-image: none;
    background: #fff;
  }
  .banner__content--category {
    display: block !important;
  }
}
.catalog-list {
  .product-grid-item {
    &__details {
      padding: 0 8px !important;
    }
    &__price {
      .price-wrapper,
      span.price {
        font-size: 14px;
      }
      .price-including-tax {
        display: block;
        line-height: 14px;
        span.price {
          font-size: 12px;
          &:after {
            content: " inc VAT";
          }
        }
      }
      .price-excluding-tax {
        display: block;
        line-height: 14px;
        span.price {
          font-size: 16px;
          &:after {
            content: " ex VAT";
          }
        }
      }
    }
    &__details-bottom {
      button {
        min-width: 38px;
        min-height: 38px;
      }
    }
  }
}
.customer-account-forgotpassword {
  #maincontent {
    .forgot-password {
      .forgot-password__info {
        color: $gray-dark;
      }
    }
  }
}
// Page Builder Updates
.pagebuilder-mobile-hidden {
  display: none !important;
}
.pagebuilder-video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .pagebuilder-column-group {
    flex-wrap: wrap;
    .pagebuilder-column {
      width: 100% !important;
    }
  }
  .pagebuilder-mobile-hidden {
    display: block !important;
  }
  .pagebuilder-mobile-only {
    display: none !important;
  }
}
.checkout-index-index {
  .required-captcha.checkbox {
    position: absolute;
    clip: rect(0 0 0 0);
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    border: 0;
  }
}
#checkout-step-shipping_method {
  .price-including-tax {
    display: block;
    line-height: 14px;
    span.price {
      font-size: 12px;
      span {
        &:after {
          content: " inc VAT";
        }
      }
    }
  }
  .price-excluding-tax {
    display: block;
    line-height: 14px;
    span.price {
      font-size: 16px;
      span {
        &:after {
          content: " ex VAT";
        }
      }
    }
  }
}
.checkout-cart-index {
  #shopping-cart-table {
    .cart-list-item__total {
      .price-including-tax {
        display: block;
        line-height: 14px;
        span.cart-price {
          font-size: 12px;
          span {
            &:after {
              content: " inc VAT";
            }
          }
        }
      }
      .price-excluding-tax {
        display: block;
        line-height: 14px;
        span.cart-price {
          font-size: 16px;
          span {
            &:after {
              content: " ex VAT";
            }
          }
        }
      }
    }
  }
}
.opc-block-summary {
  .order-summary__product-item {
    .price-including-tax {
      display: block;
      line-height: 14px;
      span.price {
        font-size: 12px;
        &:after {
          content: " inc VAT";
        }
      }
    }
    .price-excluding-tax {
      display: block;
      line-height: 14px;
      span.price {
        font-size: 16px;
        &:after {
          content: " ex VAT";
        }
      }
    }
  }
}
.homepage-cta {
  > div {
    background: rgb(52, 125, 61);
    background: linear-gradient(
      90deg,
      rgba(52, 125, 61, 1) 0%,
      rgba(66, 163, 96, 1) 100%
    );
    padding: 40px 50px;
    color: #fff;
    border-radius: 1400px 1200px 1900px 1750px;
    margin: 0 0 30px 0;
    p,
    h2 {
      color: #fff;
    }
    h2 {
      font-weight: 700;
      margin-top: 0;
    }
    a {
      color: #fff !important;
      &:hover {
        text-decoration: none;
      }
    }
  }
}

/* BLOG */
#maincontent {
  .amblog-container-list {
    .amblog-post-container {
      border: none;
      border-radius: 0;
      border-bottom: 1px solid $c-primary;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-align-content: stretch;
      -ms-flex-line-pack: stretch;
      align-content: stretch;
      -webkit-align-items: stretch;
      -ms-flex-align: stretch;
      align-items: stretch;
      a.post-image {
        width: 100%;
      }
      @include mq($screen-m) {
        a.post-image {
          width: 33%;
        }
      }
      .amblog-content {
        .amblog-date {
        }
        .amblog-post-title {
          a {
            color: $c-primary;
          }
        }
        width: 100%;
        @include mq($screen-m) {
          width: 66%;
          margin-left: 1%;
        }
      }
      .amblog-footer {
        padding: 5px 15px 0px;
        width: 100%;
        .amblog-features {
          display: block;
          .amblog-wrap {
            display: block;
            a {
              color: $c-primary;
              font-weight: 400;
            }
          }
        }
        .amblog-read {
          max-width: 140px;
          background: $c-primary;
          font-weight: 400;
          border-radius: 0;
          &:hover {
            background: $c-primary-l;
            text-decoration: none;
          }
        }
        @include mq($screen-m) {
          width: 100%;
        }
      }
    }
    @include mq($screen-m) {
      padding-right: 30px;
    }
  }
  .amblog-search-container {
    .amblog-search-titles {
      padding: 5px 20px;
      background: $c-tonal;
      margin-right: 30px;
      .amblog-search-title {
        display: inline-block;
        margin-right: 10px;
      }
    }
  }
  .amblog-element-block {
    box-shadow: none;
    border: none;
    background: $c-tonal;
    padding-bottom: 25px;
    border-radius: 0;
    .amblog-footer {
      .amblog-features {
        .amblog-posted-in {
          padding-right: 10px;
        }
        .amblog-ref {
          font-size: 16px;
          color: $c-primary;
        }
      }
    }
    .amblog-title {
      font-size: 20px;
      text-transform: none;
      color: $c-text;
      padding-bottom: 0;
    }
    .amblog-form-search {
      button {
        background: $c-primary;
        &:hover {
          background: $c-primary-l;
        }
      }
    }
    .amblog-categories {
      li {
        padding: 8px 0;
        a {
          color: $c-primary;
          font-weight: 400;
          &:hover {
            color: $c-primary-l;
          }
        }
      }
    }
    .amblog-list {
      padding-left: 25px;
      padding-right: 25px;
      a {
        color: $c-primary;
        font-weight: 400;
        &:hover {
          color: $c-primary-l;
        }
      }
    }
  }
  .column.main {
    .amblog-element-block {
      box-shadow: none;
      border: none;
      background: none;
      padding-bottom: 25px;
      border-radius: 0;
      padding-right: 30px;
      .amblog-post-container {
        border: none;
      }
    }
  }
  .amblog-related-grid {
    .amblog-item-product {
      .photo {
        display: block;
        .ratio-container {
          padding-bottom: 87.5% !important;
        }
      }
      .details {
        text-align: center;
        .product-item-link {
          display: block;
          color: #444;
          text-decoration: none;
          font-family: "Open Sans", sans-serif;
          font-weight: 300;
          line-height: 1.6;
          text-decoration: none;
          transition: all 0.3s ease-in-out;
        }
        .actions {
          button {
            border-radius: 0;
            border: none;
            outline: none;
            color: #fff;
            background: $c-primary;
            padding: 10px 20px;
            cursor: pointer;
            &:hover {
              background: $c-primary-l;
            }
          }
        }
      }
    }
  }
}
.cms-index-index {
  .page-wrapper {
    .main-banner {
      .slick-slider {
        padding: 0 !important;
      }
    }
  }
}
.amblog-post-container {
  &.-post-page {
    .amblog-content {
      .widget-product-carousel {
        .product-item {
          .product-item-photo {
            display: block;
            .ratio-container {
              padding-bottom: 100% !important;
            }
          }
          .product-item-details {
            text-align: center;
            .price-box {
              .price-including-tax {
                font-size: 14px;
                display: block;
                .price {
                  &:after {
                    content: " inc VAT";
                  }
                }
              }
              .price-excluding-tax {
                .price {
                  &:after {
                    content: " ex VAT";
                  }
                }
              }
            }
            .product-item-actions {
              button {
                border-radius: 0;
                border: none;
                outline: none;
                color: #fff;
                background: $c-primary;
                padding: 10px 20px;
                cursor: pointer;
                &:hover {
                  background: $c-primary-l;
                }
              }
              .tocompare {
                font-size: 15px;
                &:hover {
                  color: $c-primary-l;
                }
              }
            }
          }
        }
      }
    }
  }
}

.product-view {
  .amblog-relatedposts-wrap {
    .amblog-related-post {
      max-width: 350px;
      display: inline-block;
      margin-right: 20px;
      .amblog-image-wrapper {
        display: block;
        height: 200px;
        figure {
          height: 100%;
          background-size: contain;
          background-position: 0 0;
          background-repeat: no-repeat;
        }
      }
    }
  }
}

.dcc-banner {
  margin: 40px 0;
  .column-left {
    padding: 15px 0;
    h2 {
      text-transform: uppercase;
      font-weight: 900;
      font-size: 44px;
      color: #000072 !important;
      em,
      i {
        font-style: normal !important;
        color: #52e800 !important;
      }
    }
    p {
      font-weight: 900;
      color: #000072 !important;
    }
  }
  .column-right {
    position: relative;
    background: #000072;
    padding: 40px 40px 90px 40px;
    &:before {
      content: "";
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 70px 0 0 70px;
      border-color: transparent transparent transparent #fff;
      transform: rotate(0deg);
      position: absolute;
      left: 0;
      bottom: 0;
    }
    a {
      display: inline-block !important;
      padding: 12px 24px !important;
      font-weight: 700 !important;
      text-transform: uppercase !important;
      text-decoration: none !important;
      color: #000072 !important;
      background: #fff !important;
      border: 2px solid #52e800 !important;
      transition: all 0.4s ease !important;
      &:hover {
        background: #000072 !important;
        color: #fff !important;
        border: 2px solid #fff !important;
      }
    }
  }
}
