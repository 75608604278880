.search-form {
	margin-bottom: 30px;
	position: relative;
	&:before {
		content:"";
		position: absolute;
		z-index: 3;
		left: -10px;
    	top: -20px;
		background: url(../images/search_label.svg) left center / contain no-repeat;
		height: 130%;
		width: 210px;
		transition:all 0.3s ease-out;
	}
	&:hover {
		&:before {
			transform: rotate(30deg) scale(1.12);
		}
	}
	@include mq($screen-m) {
		margin-bottom: 80px;
	}

	&__wrapper {
		background: $white;
		border-radius: 1000px;
		padding: $spacer--medium $spacer--extra-large;
		box-shadow: 0 0 30px rgba(0,0,0,0.2);

		@include mq($screen-m) {
			padding: $spacer--extra-large;
		}
	}

	&__input-wrapper {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		position: relative;
		z-index: 5;

		@include mq($screen-m) {
			flex-wrap: nowrap;
		}

		.label {
			font-size: 17px;
			line-height: 1.2;
			flex: 1 0 100%;
			margin: 0 0 $spacer;

			@include mq($screen-m) {
				flex: 1 0 160px;
				margin: 0;

				strong {
					display: block;
				}
			}
		}
		.search-form__input {
			border-radius: 0;
			border:none;
			border-bottom: 2px solid #000;
			background: none;
			&.focus-visible {
				border-color: $color-secondary;
			}
		}
	}
	&__input {
		border-radius: 10000px;
		height: 55px;
		background: $gray-lightest;
		line-height: 1.2;
		width: calc(100% - 65px);

		@include mq($screen-m) {
			width: 100%;
		}
	}
	&__button {
		position: relative;
		transform: none;
		background: $color-secondary;
		border-radius: 1000px;
		min-width: 55px;
		overflow: hidden;
	}
}


.main-banner {
	+ #maincontent {
		position: relative;
		z-index: 12;
		.search-form {
			padding-top: 20px;

			@include mq($screen-m) {
				padding-top: 0;
				margin-bottom: 30px;
			}
		}
	}
}
.catalogsearch-result-index {
	#maincontent {
		.catalog-grid {		
			@include mq($screen-xxl) {
				grid-template-columns: 1fr 1fr 1fr;
			}
		}
	}
}
#search_autocomplete {
	.quicksearch__list {
		.price-final_price {
			span.price-wrapper {
				font-size: 24px;
				&:after {
					content: "inc VAT";
					font-size: 12px;
				}
			}
		}
		.price-including-tax {
			span.price {
				font-size: 18px;
				&:after {
					content: "inc VAT";
					font-size: 10px;
				}
			}
		}
		.price-excluding-tax {
			span.price {
				font-size: 24px;
				&:after {
					content: "ex VAT";
					font-size: 12px;
				}
			}
		}
	}
}