.featured-cats {
    @include global-padding;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    @include mq($screen-m) {
        grid-template-columns: 1fr 1fr;
    }
    @include mq($screen-m) {
        grid-template-columns: 1fr 1fr 1fr;
    }
    @include mq($screen-l) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 50px;
    }

    @include mq($screen-xl) {
        grid-gap: 60px;
    }

    &__item {
        background: $white;
        border-radius: $border-radius;
        overflow: hidden;
        box-shadow: 0 0 30px rgba(0,0,0,0.2);
        padding:40px 40px 30px 40px;
        position: relative;
        overflow: visible;
        &:before {
            content:"";
            position: absolute;
            top: -50px;
            left: -50px;
            width: 80px;
            height: 140px;
            z-index: 12;
            background: url(../images/dot_bg.png) repeat;
        }
        @include mq($screen-l) {
            align-items: center;
        }
        .featured-cats__image {
            img {
                transition:all 0.5s ease-out;
            }
        }
        &:hover {
            .featured-cats__image {
                img {
                    transform:scale(1.15);
                }
            }
        }

        > div {
            width: 100%;
        }
        .cat-icon {
            position: absolute;
            top: -20px;
            left: -20px;
            width: 100px;
            height: 100px;
            z-index: 10;
            .icon-wrapper {
                position: absolute;
                top: 50%;
                left: 50%;
                transform:translate(-50%,-50%);
            }
        }
        &:nth-of-type(1n) {
            .cat-icon {
                background: url(../images/cat_blob_1.svg) left center / contain no-repeat;
            }
        }
        &:nth-of-type(2n) {
            .cat-icon {
                background: url(../images/cat_blob_4.svg) left center / contain no-repeat;
            }
        }
        &:nth-of-type(3n) {
            .cat-icon {
                background: url(../images/cat_blob_3.svg) left center / contain no-repeat;
            }
        }
        &:nth-of-type(4n) {
            .cat-icon {
                background: url(../images/cat_blob_2.svg) left center / contain no-repeat;
            }
        }
        &:nth-of-type(5n) {
            .cat-icon {
                background: url(../images/cat_blob_5.svg) left center / contain no-repeat;
            }
        }
        &:nth-of-type(8n) {
            .cat-icon {
                background: url(../images/cat_blob_5.svg) left center / contain no-repeat;
            }
        }
    }
    

    &__link {
        border-bottom: 3px dotted $color-primary;

        &:hover {
            text-decoration: none;
            color: $color-secondary;
            border-bottom-color: $color-secondary;
        }
    }

    &__image {
        border-radius: $border-radius;
        overflow: hidden;

        a {
            display: block;
        }
    }

    &__name {
        font-size: 18px;

        a {
            color: $headings-color;
            text-decoration: none;
            font-weight: $font-weight-bold;
        }
    }

    &__content {
        &__inner {
            padding-top: 20px;
        }
    }
}