.contact-wrapper {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    > div {
        padding: 50px;
        width: 100%;
    }
    @include mq($screen-m) {
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        > div {
            width: 50%;
        }
    }

    &__section-1 {
        background: $gray-lightest;

        .col-xs-12 {
            width: 100%;
            max-width: 100%;
            flex-basis: 100%;
        }

        .input__field, .input.date .datetime-picker, .input--datepicker input, .quote-items .quote-table-items .input-text {
            background: $white;
        }
    }

    &__section-2 {
    }
    .contact-us__tips, div.required label:not(.checkbox__label):after {
        color: $green
    }
}