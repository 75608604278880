.auth__links {
	margin-bottom: $spacer--medium;
	
	a.button--link {
		background: $gray-lightest;

		&:hover {
			background: $color-secondary;
		}
	}
}




.account-form {
	background: $gray-lightest;

	.input {
		&__field, .input-text {
			background: $white;
		}
	}

	.select--native {
		select {
			background-color: $white;
		}
	} 
}

.dashboard-nav {
	&__mobile, &__item > a, .nav.item > a, &__link, .nav.item.current {
		border-radius: 1000px;

		&:after {
			border-color: transparent $paragraph-color  $paragraph-color  transparent;
		}
	}
}

.dashboard-nav__item > a:hover, .dashboard-nav .nav.item > a:hover, .dashboard-nav__link:hover, .dashboard-nav .nav.item.current:hover, .dashboard-nav .nav.item > a:hover {
	background: $color-secondary;
	color: $white;
}

.dashboard-items {
	&__subtitle {
		margin-top: 0;
		font-weight: $font-weight-medium;
	}
	&__title {
		color: $white;
	}
}

.dashboard-table {
	&__title {
		color: $white;
	}

	&__link {
		color: $white;
		text-transform: uppercase;
		text-decoration: none;

		&:hover {
			color: $color-secondary;
			text-decoration: none;
		}
	}
}