.minicart-content {
    &__actions {
        .button {
            overflow: hidden;
        }
    }
}

.minicart-product {
    &__name {
        margin-top: 0;
        padding-right: 15px;
    }
    &__price {
        .product__price, span.price {
            font-size: 14px;
        }
        .price-including-tax {
            display: block;
            line-height: 14px;
            span.price {
                font-size: 12px;
                &:after {
                    content:" inc VAT";
                }
            }
        }
        .price-excluding-tax {
            display: block;
            line-height: 14px;
            span.price {
                font-size: 16px;
                &:after {
                    content:" ex VAT";
                }
            }
        }
    }
}
.minicart-content__summary-value {
    .price-including-tax {
        .price {
            font-size: 14px;
            &:after {
                content:" inc VAT";
            }
        }
    }
    .price-excluding-tax {
        .price {
            font-size: 20px;
            &:after {
                content:" ex VAT";
            }
        }
    }
}