.cart-grid {
	.cart-list-item {
		&__image {
			.ratio-container {
				padding-bottom: 120% !important;

				.ratio-image {
					top: 0;
					transform: translate(-50%,0);
				}
			}
		}
		&__price {
			.price-including-tax {
				display: inline-block;
				line-height: 14px;
				span.price {
					font-size: 12px;
					&:after {
						content:" inc VAT";
					}
				}
			}
			.price-excluding-tax {
				display: inline-block;
				line-height: 14px;
				span.price {
					font-size: 16px;
					&:after {
						content:" ex VAT";
					}
				}
			}
		}
	}
}

.form-cart {
	@include mq($screen-m) {
		padding-right: 50px;
	}

	@include mq($screen-l) {
		padding-right: 80px;
	}
}

.cart__summary {
	input {
		background: $white;
	}
	
	.select--native {
		select {
			background-color: $white;
		}
	}
}

.input {
	&__field {
		border-radius: 1000px;
		background: $gray-lightest;
	}
}

.cart-discount {
	&__button-text {
		display: inline-block;
		color: #fff;
		padding: 30px;
		border-radius: 80px 60px 90px 40px;
		background: $color-primary;
		font-weight: 700;
	}
}
