.main-banner {
    position: relative;
    overflow: hidden;
    &:after {
        content:"";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 10;
        height: 20%;
        background: url(../images/banner_overlay.svg) center / cover no-repeat;
        padding-top: 28%;
        pointer-events: none;
    }
    .caption {
        position: absolute;
        top: 50%;
        left: 0px;
        transform: translateY(-50%);
        background: url(../images/banner_blob.svg) left / contain no-repeat;
        padding: 8% 3%;
        z-index: 11;
        .title {
            a {
                color: #fff;
                font-weight: 700;
                text-decoration: none;
                &:hover {
                    color: $yellow;
                }
            }
        }
        .container {
            padding-left: 0;
            width: 50%;
            @include mq($screen-l) {
                padding-left: 10%;
                width: 100%;
            }
        }
        @include mq($screen-l) {
            left:0px;
            background-size: 100%;
            padding-left: 5%;
        }
        @include mq($screen-xl) {
            background-size: contain;
        }        
        @include mq($screen-xxl) {
            left: 0px;
        }

        h2 {
            color: $black;
            margin-top: 0;
            text-transform: uppercase;
            font-size: 16px;
            display: inline-block;
            position: relative;
            font-weight: 900;
            padding: $spacer $spacer--medium $spacer 0;
            z-index: 1;
            strong {
                color: #fff;
                font-weight: 300;
            }

            @include mq($screen-s) {
                font-size: $font-size-large;
            }

            @include mq($screen-l) {
                font-size: $font-size-extra-large;
            }
        }

        p {
            display: none;
            
            @include mq($screen-l) {
                display: block;
                max-width: 500px;
                color: $black;
                font-size: 18px;
                margin-bottom: $spacer--medium;
            }
        }
    }

    .container {
        max-width: 100%;
    }

    .btn-banner {
        font-weight: $font-weight-medium;
        overflow: hidden;

        @extend %button-before;
        @extend %button-after;

        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        min-height: $button__min-size;
        min-width: $button__min-size;
        padding: $button__padding;

        background-color: $button__background;
        border: $button__border;
        border-radius: $button__border-radius;
        outline: $button__outline;

        font-family: $button__font-family;
        font-size: $button__font-size;
        font-weight: $button__font-weight;
        line-height: $button__line-height;
        color: $button__text-color;

        cursor: pointer;

        text-transform: $button__text-transform;
        text-decoration: $button__text-decoration;
        transform: $button__transform;
        transition: $button__transition;

        &:hover {
            background-color: $button__background-hover;
            text-decoration: $button__text-decoration-hover;

            &:before {
                display: $button__before-display-hover;
                transform: $button__before-transform-hover;
            }

            &:after {
                display: $button__after-display-hover;
                left: $button__after-position-left-hover;
                opacity: $button__after-opacity-hover;
            }
        }
    }
    .image-thumb {
        img {
            width: 100%;
        }
    }
}

.banner {
    background: $gray-lightest;
    margin: 0;
    overflow: hidden;

    &__heading-wrapper {
        padding: 50px 0 !important;
        font-size: $font-size-large;
		font-weight: 300;
		color: $white;
		line-height: 2.1 !important;
        margin-top: 0;
        display: block !important;
        text-align: center;
        background: none !important;
        max-width: unset !important;
        position: static !important;

		@include mq($screen-m) {
			font-size: $font-size-extra-large;
			line-height: 2 ;
		}

		@include mq($screen-xl) {
			font-size: 40px;
			line-height: 1.8;
		}
    }

    &__heading {
        padding: 8px 20px;
        box-decoration-break: clone;
        background: $green;
        
        display: inline;
        color: $white;
        font-weight: $font-weight-normal;
        position: relative;
    }

    .heading--page {
        font-weight: $font-weight-normal;
        color: $white;
        text-transform: uppercase;
        font-size: $font-size-large;
        display: inline;

		@include mq($screen-m) {
			font-size: $font-size-extra-large;
		}

		@include mq($screen-xl) {
			font-size: 40px;
		}
    }

    &.banner--category {
        .ratio-image {
            height: 100%;
        }
    }

    &__image-wrapper {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        transform: translate(-50%,-50%);

        .ratio-container {
            padding: 0 !important;
            height: 100%;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
    }
}

.cms-page-view, .customer-account-login, .customer-account-create, .contact-index-index, .catalogsearch-result-index, .catalog-product_compare-index, .checkout-cart-index, .amblog-index-index, .amblog-index-post {
    .banner {
        background: url(../images/banner.jpg) center / cover no-repeat;
    }
}

.account {
    .banner {
        background: none;
        margin-bottom: $spacer--large;
    
        &__heading-wrapper {
            background: none !important;
            padding: 0 !important;
        }
    }
}
