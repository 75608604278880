.header {
    @include global-padding;
    background: $color-primary;
    .header__wrapper {
        border-bottom: 1px solid rgba(255,255,255,0.5);
    }
    .logo {
        max-width: 200px;
        @include mq($screen-l) {
            order: 1;
        }
        
        &__image {
            height: auto;

        }
    }

    &__wrapper {
        align-items: center;
    }
    
    &__search-wrapper {
        position: fixed;
        background: rgba(0, 0, 0, 0.6);
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        max-width: unset;
        z-index: 1000;
        display: block;
        padding-top: 133px;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        transition: all 0.3s ease;

        &.active {
            opacity: 1;
            visibility: visible;
            pointer-events: all;
        }

        .container {
            width: 100%;
        }

        &__close {
            background: $color-secondary;
            border-radius: 1000px;
            padding: 15px 30px;
            position: absolute;
            top: 25px;
            right: 20px;
            border: 0;
            cursor: pointer;
            transition: all 0.3s ease;

            &:hover {
                background: $white;
            }
	
            @include mq($screen-l) {
                right: 50px;
            }
            
            @include mq($screen-xxl) {
                right: 100px;
            }

            svg {
                width: 20px;
                height: 20px;
            }

            span,
            svg {
                display: inline-block;
                vertical-align: middle;
            }
        }
    }

    .header__minicart {
        border: none;
    }

    .header-button {
        flex-wrap: wrap;
        flex-direction: column;
        padding: 0;
        margin-right: $spacer--medium;
        background: none;

        &:hover {
            .header-button__title {
                opacity: 0;
            }
        }

        &__counter-dot {
            @include visually-hidden();
        }

        &__summary-count {
            position: static;
            clip: unset;
            width: 26px;
            height: 16px;
            margin: -1px;
            padding: 0;
            border: 0;
        }

        &__title {
            font-weight: $font-weight-normal;
            text-transform: none;
            font-size: $font-size-base;
            color: $white;
            margin-top: $spacer;
            display: block;
        }

        &__counter {
            width: 24px;
            height: 24px;
        }
    }

    
    &__buttons {
        max-width: unset;

        @include mq($screen-m) {
            flex: 0 0 260px;
        }
    }
    &__menu-btn {
        max-width: unset;
        background: url(../images/menu_toggle.svg) left center / contain no-repeat;
        .hamburger-label {
            display: none;
        }
        @include mq($screen-m) {
            flex: 0 0 50px;
        }
    }

    &__menu-btn {
        order: 1;
        margin-left: $spacer--medium;

        @include mq($screen-m) {
            order: 4;
            margin-left: 0;
        }
    }

    .button--icon-light {
        background: $white;
        overflow: hidden;
    }

    &__buttons {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 100;
        justify-content: center;
        padding: $spacer 0;
        background: $color-primary;

        @include mq($screen-m) {
            position: static;
            width: unset;
            justify-content: flex-end;
            background: unset;
            padding: 0;
            margin-left: auto;
        }
    }
}
.header {
    background: none;
    background:#fff;
    position: static;
    padding-top: 15px;
    transition:all 0.3s ease-out;
    .header__buttons {
        .header-button__icon__cart-a, .header-button__icon__account-a, .header-button__icon__search-a {
            fill:$color-primary !important;
        }
        .header-button__title {
            color: $color-primary;
        }
    }
    &.fixed {
        position: fixed;
        z-index: 999;
        background: #fff;
        top: 0;
        left: 0;
        right: 0;
        .header__buttons {
            background: #fff;
            .header-button__icon__cart-a, .header-button__icon__account-a, .header-button__icon__search-a {
                fill:$color-primary !important;
            }
            .header-button__title {
                color: $color-primary;
            }
        }
    }
}
.cms-index-index {
    .header {
        background: none;
        position: static;
        padding-top: 15px;
        transition:all 0.3s ease-out;
        @include mq($screen-m) {
            background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(3,1,1,0.6362920168067228) 100%);
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            z-index: 12;
        }
        .header__buttons {
            .header-button__icon__cart-a, .header-button__icon__account-a, .header-button__icon__search-a {
                fill:#fff !important;
            }
            .header-button__title {
                color: #fff;
            }
        }
        &.fixed {
            position: fixed;
            z-index: 999;
            background: #fff;
            .header__buttons {
                .header-button__icon__cart-a, .header-button__icon__account-a, .header-button__icon__search-a {
                    fill:$color-primary !important;
                }
                .header-button__title {
                    color: $color-primary;
                }
            }
        }
    }
}
