@mixin global-padding {
	padding-left: 20px;
	padding-right: 20px;
	
	@include mq($screen-l) {
		padding-left: 50px;
		padding-right: 50px;
	}
	
	@include mq($screen-xxl) {
		padding-left: 100px;
		padding-right: 100px;
	}
}

@mixin global-margin {
	margin-left: 20px;
	margin-right: 20px;
	
	@include mq($screen-l) {
		margin-left: 50px;
		margin-right: 50px;
	}
	
	@include mq($screen-xxl) {
		margin-left: 100px;
		margin-right: 100px;
	}
}