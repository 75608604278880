.input {
	&__field, .input-text {
		border-radius: 1000px;
		outline: none !important;
	}
}
.select--native {
	select {
		border-radius: 1000px;
	}
}

.checkbox {
	&__label {
		
	}
}