.total-partner {
  background: #fff;
  border-radius: $border-radius;
  padding: 20px 30px 20px 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
  margin-top: 40px;
  position: relative;
  z-index: 12;
  text-align: center;

  @include mq($screen-m) {
    margin-bottom: 20px;
    margin-top: 60px;
    flex-wrap: nowrap;
    border-radius: 1000px;
    text-align: left;
  }

  &__image {
    padding: 0px 30px 0px 15px;
    max-width: 480px;
    margin: 0 auto 0;
    @include mq($screen-m) {
      margin-left: unset;
      margin-right: 20px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }

  p {
    margin: 0 auto 0;
    color: $white;

    @include mq($screen-m) {
      margin-left: unset;
      margin-right: 20px;
    }
  }

  &__button {
    background: $color-secondary;
    border-radius: 1000px;
    height: 55px;
    padding: 0 25px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $font-color-base;
    font-weight: $font-weight-bold;

    @include mq($screen-m) {
      max-width: 300px;
      margin-left: auto;
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
    }

    &:hover {
      background: $white;
      text-decoration: none;
      color: $font-color-base;
    }

    svg {
      width: 11px;
      height: 18px;
    }
  }
}
