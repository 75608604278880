.select2-container .select2-selection--single {
  border-radius: 1000px !important;
}

.select2-selection select2-selection--single {
  outline: none !important;
}
.select2-container.select2-container--open .select2-selection--single {
  outline: none !important;
}

.select2-container .select2-dropdown {
  border: 1px solid $gray;
}

#select2-limiter-results {
  li {
    transition: all 0.3s ease;
  }
}
