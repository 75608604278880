.product-view {
  max-width: unset;
  @include global-padding;
  .add-to-cart {
    position: static !important;
    border: none;
    box-shadow: none;
  }
  &__details {
    width: 100%;
  }

  &__wrapper {
    max-width: unset;
  }

  &__button {
    overflow: hidden;
  }

  &__tabs {
    max-width: unset;
    margin-top: 30px !important;
  }
  .gallery-placeholder {
    padding-bottom: 0 !important;
  }

  &__price {
    border-bottom: 1px solid $gray;
    color: $color-primary;
    .price-final_price {
      span.price {
        font-size: 24px;
        &:after {
          content: "inc VAT";
          font-size: 12px;
        }
      }
      .price-including-tax {
        span.price {
          font-size: 18px;
          &:after {
            content: "inc VAT";
            font-size: 10px;
          }
        }
      }
      .price-excluding-tax {
        span.price {
          font-size: 24px;
          &:after {
            content: "ex VAT";
            font-size: 12px;
          }
        }
      }
    }
  }

  &__qty {
    border-radius: 1000px;
    background: $gray-lightest;
    display: flex;
    align-items: center;
    padding: $spacer--small $spacer--large;

    .label {
      font-size: 18px;
      margin-right: 15px;
    }

    .quantity-update {
      border: 0 !important;

      &__input {
        border: 0;
        background: transparent;
      }

      &__button {
        overflow: hidden;
        border: 1px solid $gray;
      }
    }
  }
  .product-view__grouped-table {
    .product-view__grouped-product-name-cell {
      .price-including-tax {
        font-size: 14px;
        &:after {
            content:" inc VAT";
        }
      }
      .price-excluding-tax {
        font-size: 20px;
        &:after {
            content:" ex VAT";
        }
      }
    }
    td {
      div.unavailable {
        padding-top: 9px;
        @include mq($screen-m) {
          padding-top: 0;
          padding-left: 12px;
        }
      }
    }
  }
  .add-to-cart.aos-init {
    .heading--page {
      display: none;
    }
  }
}
