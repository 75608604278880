.catalog-grid {
    grid-template-columns: 1fr 1fr;

    @include mq($screen-m) {
        grid-template-columns: 1fr 1fr 1fr;
    }

    @include mq($screen-xxl) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

.product-grid-item {
    &__image-wrapper {
        .ratio-container {
            padding-bottom: 100% !important;
            .ratio-image {
                height: 100%;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}

.product-list-item {
    .ratio-container {
        padding-bottom: 100% !important;
        .ratio-image {
            height: 100%;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}
.categories-list {
    .categories-list__link {
        font-size: 15px;
        padding: 8px 4px;
    }
}
