/* ---------------------------------------------------------
Variables
--------------------------------------------------------- */

// Colors
$blue: #004196;
$bright-sky-blue: #4b96cd;
$orange: #f59600;
$red: #d22f3b;
$green: #2f894c;
$yellow: #f5c247;

// Grayscale colors
$gray: #d0d0d0;
$gray-darkest: #010527;
$gray-dark: #444;
$gray-lightest: #e8e8e8;
$off-white: #f5f5f5;
$c-tonal:$off-white;
$c-text:$gray-dark;

$c-primary:$green;
$c-primary-l:lighten($green, 10%);

// Semantic scheme
$color-primary: $green;
$color-secondary: $yellow;
$paragraph-color: $gray-dark;

// Typography
$font-family-sans-serif: "Open Sans", sans-serif;
$font-family-serif: "Open Sans", sans-serif;
$font-family-base: $font-family-sans-serif;
$font-color-base: $gray-dark;
$font-weight-normal: 300;
$font-weight-extra-bold: 900;

$font-size-super-extra-large: 66px;
$font-size-extra-large: 30px;
$font-size-large: 24px;
$font-size-base: 16px;
$font-size-small: 14px;
$font-size-extra-small: 12px;

// Headings
$headings-font-family: $font-family-base;
$headings-color: $gray-darkest;
$headings-margin: $spacer--extra-large 0 $spacer--medium;
$headings-margin--page: 0 0 $spacer--large;
$headings-line-height: 1.1;
$headings-text-transform--page: none;

$heading-font-size--page: $font-size-extra-large;
$heading-font-size--first-level: $font-size-large;
$heading-font-size--first-level\@screen-m: $font-size-extra-large;
$heading-font-size--second-level: $font-size-large;
$heading-font-size--second-level\@screen-m: $font-size-large;
$heading-font-size--third-level: $font-size-medium;
$heading-font-size--fourth-level: $font-size-base;
$heading-font-size--fifth-level: $font-size-small;
$heading-font-size--sixth-level: $font-size-extra-small;
$headings-text-transform: none;
$headings-text-transform--page: none;

/* ---------------------------------------------------------
Global Styles
--------------------------------------------------------- */
$container__margin: 20px;
$container__margin\@medium: 20px;

// Components
$border-radius: 5px;

// Search-form.scss
$search-form__input-height: 55px;
$search-form__button-border: none;
$search-form__button-height: 55px;

// Input.scss
$input__placeholder-color: $paragraph-color;
$input__label-focus-color: $gray-darkest;
$input__field-spacing: 55px;
$input__field-border: 1px solid $gray;

// Select
$select__border: solid 1px $gray;
$select__input-border-color: $gray;
$select__height: 55px;

// Label.scss
$label-color: $headings-color;

// Button.scss
$button__border-radius: 1000px;
$button__background: $color-secondary;
$button__font-weight: $font-weight-medium;
$button__text-transform: none;
$button__text-color: $gray-darkest;
$button__min-size: 55px;
$button__padding: 0 $spacer--extra-large;

/* Breadcrumbs */
$breadcrumbs__padding: 3px 15px;
$breadcrumbs__color: $gray-darkest;
$breadcrumbs__color--active: $gray-darkest;
$breadcrumbs__min-height: 0;

// Message.scss
$message__border-radius: 1000px;
$message__padding: $spacer--medium $spacer--extra-large;
$message__font-size: $font-size-base;
$message__background: $blue;
$message__background--success: $green;
$message__background--error: $red;
$message__color: $white;
$message__color--success: $white;
$message__color--error: $white;
$message__border--success: 1px solid $green;
$message__icon-fill: $white;
$message__icon-fill--success: $white;
$message__icon-fill--error: $white;

//Counter
$header-button__counter-background: $color-secondary;
$header-button__counter-size: 16px;
$header-button__counter-color: $black;
$header-button__counter-top: -5px;
$header-button__counter-right: 0;
$header-button__counter-font-size: 12px;

//Footer {
$footer__border: none;
$footer__background-color: $off-white;
$footer__bottom-bar-color: $paragraph-color;
$footer__bottom-bar-background: $off-white;
$footer__copywrite-color: $paragraph-color;

/* ---------------------------------------------------------
Catalog
--------------------------------------------------------- */
$product-view__button-bg: $gray-lightest;
$product-view__title-font-size\@large: $font-size-extra-large;
$product-view__title-font-size\@medium: $font-size-large;
$product-view__title-font-size: $font-size-large;

$tab__title-max-width\@large: unset;
$tab__title-text-transform: none;
$tab__title-background--active: $color-primary;
$tab__title-color--active: $white;
$tab__title-font-weight: $font-weight-normal;
$tab__title-font-weight--active: $font-weight-normal;
$tab__content-padding\@extra-large: 50px 0;
$tab__content-padding\@large: 50px 0;
$tab__content-padding\@medium: 30px 0;
$tab__content-padding: 30px 0;
$tab__title-font-size: 18px;
$tab__title-border: 0;

$toolbar__amount-color: $font-color-base;

$product-list-item__sku-color: $font-color-base;
$product-list-item__price-margin\@medium: $spacer--medium 0 0;
$product-list-item__price-font-size\@medium: $font-size-large;

/* ---------------------------------------------------------
Checkout
--------------------------------------------------------- */
$order-summary__subtitle-font-size: 18px;
$order-summary__background: $gray-lightest;

$cart-discount__button-text-font-size: 18px;
$discount-code__button-text-font-size: 18px;
$discount-code__button-text-text-transform: none;
$discount-code__button-text-font-weight: $font-weight-medium;

/* ---------------------------------------------------------
Account
--------------------------------------------------------- */
$auth__link-font-size: 18px;
$auth__link-background--active: $color-primary;
$auth__link-color--active: $white;
$auth__link-color: $color-primary;
$auth__link-border: none;

$dashboard-items__title-background: $color-primary;
$dashboard-items__title-border: none;
$dashboard-items__title-font-size: 18px;

$dashboard-table__header-background: $color-primary;
$dashboard-table__header-border: none;
$dashboard-table__title-font-size: 18px;
